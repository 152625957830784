import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';
import { useEffect } from 'react';
import MainPage from './pages/mainPage/mainPage';
import MenuPage from './pages/menuPage/menuPage';
import ContactsPage from './pages/contactsPage/contactsPage';
import CatalogPage from './pages/catalogPage/catalogPage';
import ProductPage from './pages/productPage/productPage';

function App() {

  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 0);
  }, [location]);

  return (
    <Routes location={location} key={location.pathname}>
      <Route path='/' element={<MainPage />} />
      <Route path='/:lang/' element={<MenuPage />} />
      <Route path='/:lang/:catalog' element={<CatalogPage />} />
      <Route path='/:lang/:catalog/:id' element={<ProductPage />} />
      <Route path='/:lang/contacts' element={<ContactsPage />} />
    </Routes>
  );
}

export default App;
