import React from "react";
import "./card.css"
import { Link, useParams } from "react-router-dom";
import clockIcon from "../../images/Clock.svg"

function Card(props) {
    const params = useParams()

    return (
        <Link to={`/${params.lang}/${params.catalog}/${props.data.id}`} className="card">
            < img className="card__image" src={`/admin/images/${params.catalog}/${props.data.preview_image}`
            } alt={props.data[`title_${params.lang}`]} />
            <div className="card__info-block">
                <h3 className="card__title">
                    {props.data[`title_${params.lang}`]}
                </h3>
                <p className="card__price">
                    {props.data.price} {params.lang === "ru" ? "сум" : params.lang === "uz" ? "so'm" : "soum"}
                </p>
                <p className="card__cooking-time">
                    <img className="card__cooking-time-icon" src={clockIcon} alt="clock" />{props.data.cooking_time} {params.lang === "ru" ? "мин" : "min"}
                </p>
            </div>
        </Link >
    )
}

export default Card