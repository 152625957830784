import React, { useEffect, useState } from "react";
import "./productPage.css"
import Header from "../../components/header/header";
import { useParams } from "react-router-dom";
import axios from "axios";


function ProductPage() {
    const params = useParams()
    const [data, setData] = useState()


    useEffect(() => {
        axios.get(`https://menu.blinniy-domik.uz/admin/api/${params.catalog}-api.php`)
            .then(res => {

                const item = res.data.find(item => item.id === Number(params.id));
                setData(item);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    if (!data) return false

    return (
        <>
            <Header showCatalogList={false} title={data[`title_${params.lang}`]} />
            <main className="main">
                <div className="product-info__block">
                    <img className="product-info__img" src={`/admin/images/${params.catalog}/${data.main_image}`} alt={data[`title_${params.lang}`]} />
                    <div className="product-info__des">
                        {params.lang === "ru" ? "Состав:" : params.lang === "uz" ? "Tarkibi:" : "Compound"}
                        <p>{data[`des_${params.lang}`]}</p>
                    </div>
                    <p className="product-info__price">
                        {data.price} {params.lang === "ru" ? "сум" : params.lang === "uz" ? "so'm" : "soum"}
                    </p>
                    <p className="product-info__cooking-time">
                        {params.lang === "ru" ? "Время приготовления" : params.lang === "uz" ? "Pishirish vaqti" : "Cooking time"} {data.cooking_time} {params.lang === "ru" ? "мин" : "min"}
                    </p>
                </div>
            </main>
        </>
    )
}


export default ProductPage